import logo from './sphere.gif';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
import { Container, Box, Heading, Image, AbsoluteCenter } from '@chakra-ui/react'

function App() {
  return (
    <>
    <ChakraProvider>

    <Box className="main-container">
    <Box className="home-background">



    <Heading className="homepage-header">
    404 <br/>

    Page Not Found
    </Heading>

      <AbsoluteCenter >
    <Image

    minWidth='300px'
    borderRadius='full'
    src={logo} />
    </AbsoluteCenter>
    </Box>
    </Box>

    </ChakraProvider>
    </>
  );
}

export default App;
